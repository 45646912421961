/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';

import Root from './components/Root';
import reportWebVitals from './reportWebVitals';

import cookies from './data/cookies.json';

import data from './data/root';

import './styles/main.global.scss';

ReactDOM.render(
    <React.StrictMode>
        <Root {...data} micromag={cookies} locale="en" />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); // eslint-disable-line
